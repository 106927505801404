<template>
  <div id="app">
    <router-view />
    <div id="connecting" :class="{ visible: this.connecting }">
      <div class="loader">
        <div class="loader-inner ball-clip-rotate">
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiClient from "@/module/api";

export default {
  computed: {
    connecting: function() {
      return this.$store.state.connecting;
    }
  },
  async created() {
    const obj = this;
    setInterval(async function() {
      const count = obj.$store.getters.getConnectCount;
      if (count == 0) {
        const api = new ApiClient();
        await api.get(`/reload`);
      }
      obj.$store.dispatch(`initConnectCount`);
    }, 1000 * 60 * 3);

    if (sessionStorage.getItem("access") == null) {
      console.log("access");
      sessionStorage.setItem("access", "1");
      location.reload(true)
    } else {
      console.log("in session");
    }
  },
};
</script>

<style lang="scss">
#connecting {
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
  transition: all 0.5s ease;
  &.visible {
    opacity: 0.7;
    z-index: 100;
  }
}
#nav {
  padding: 15px;
  position: fixed;
}

.dialog-form {
  .error {
    color: red;
    white-space: nowrap;
  }
}

.loader {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in-out 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ball-clip-rotate > div {
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  height: 40px;
  width: 40px;
  background: transparent !important;
  display: inline-block;
  -webkit-animation: rotate 0.75s 0s linear infinite;
  animation: rotate 0.75s 0s linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
</style>
