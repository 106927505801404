import axios from "axios";
import store from "@/store";
// import router from "@/router";
// import Vue from "vue";

// Vue.use(router);

class ApiClient {
  constructor() {
    this.axiosBase = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL
    });
  }

  get(endPoint, successCallback = null, failCallback = null) {
    store.dispatch("setConnecting", true);
    const header = {
      headers: {
        token: store.getters.token,
        contentType: "application/json"
      }
    };
    const result = this.axiosBase
      .get(endPoint, header)
      .then(res => {
        if (!this._resultParse(res)) {
          return;
        }
        if (successCallback) {
          successCallback(res);
        }
        return res.data.data;
      })
      .catch(err => {
        store.dispatch("setConnecting", false);
        if (failCallback) {
          failCallback(err);
        }
        return err;
      });
    return result;
  }

  post(endPoint, param = null, addHeader = true) {
    store.dispatch("setConnecting", true);
    let header = {};
    if (addHeader) {
      header = {
        headers: {
          token: store.getters["token"],
          contentType: "application/json"
        }
      };
    }
    const params = new URLSearchParams();
    if (param) {
      const json = JSON.stringify(param);
      params.append("data", json);
    }
    const result = this.axiosBase
      .post(endPoint, params, header)
      .then(res => {
        if (!this._resultParse(res)) {
          return;
        }
        return res.data.data;
      })
      .catch(err => {
        store.dispatch("setConnecting", false);
        return err;
      });
    return result;
  }

  getErrors(result) {
    let isHit = false;
    let errors = {};
    Object.keys(result.errors).forEach(function(key) {
      errors[key] = result.errors[key][0];
      isHit = true;
    });

    return {
      isHit: isHit,
      errors: errors
    };
  }

  /**
   * 郵便番号検索 外部サービス用
   */
  searchZip(zipcode) {
    const axiosCtrl = axios.create({
      baseURL: "https://zipcloud.ibsnet.co.jp"
    });
    const result = axiosCtrl
      .get(`/api/search?zipcode=${zipcode}`)
      .then(res => {
        return res.data.results;
      })
      .catch(err => {
        return err;
      });
    return result;
  }

  _resultParse(res) {
    store.dispatch("setConnecting", false);
    if (res.data.data.state === 444 || res.data.data.state === 500) {
      return false;
    }
    store.dispatch("token", res.data.data._code);
    return true;
  }
}

export default ApiClient;
