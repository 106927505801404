import Vue from "vue";
import Vuex from "vuex";
import shopOrder from "./modules/shopOrder";
import iseyaOrder from "./modules/iseyaOrder";
import shopLogin from "./modules/shopLogin";
import VueCookies from "vue-cookies";
import axios from "axios";
import ApiClient from "@/module/api";
Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  state: {
    token: null,
    products: [],
    transfers: null,
    prefectures: null,
    shops: [],
    owners: [],
    fixedMenu: true, //#fixedMenuの表示状態
    todaysLock: false, //本日締切分の受付状況
    deadLineLabel: true, //締切ラベルの表示状態
    connecting: false, // 通信中フラグ
    connectCount: 0
  },
  modules: {
    shopOrder,
    shopLogin,
    iseyaOrder
  },
  mutations: {
    token(state, token) {
      state.token = token;
      let cookieName = "__jwt_token";
      if (window.location.pathname.indexOf("/iseya") === 0) {
        cookieName = "__jwt_token_iseya";
      }
      VueCookies.set(cookieName, token);
    },
    setProducts: function(state, products) {
      state.products = products;
    },
    setTransfers: function(state, transfers) {
      state.transfers = transfers;
    },
    setShops: function(state, shops) {
      state.shops = shops;
    },
    setOwners: function(state, owners) {
      state.owners = owners;
    },
    setConnecting: function(state, flg) {
      state.connecting = flg;
      state.connectCount++;
    },
    setTodaysLock: function(state, flg) {
      state.todaysLock = flg;
    },
    initConnectCount: function(state) {
      state.connectCount = 0;
    }
  },
  actions: {
    token(state, token) {
      state.commit("token", token);
    },
    getProducts: async function({ commit }) {
      const api = new ApiClient();
      const result = await api.get("/store/item/list");
      commit("setProducts", result.list);
    },
    setConnecting(state, flg) {
      state.commit("setConnecting", flg);
    },
    initConnectCount(state) {
      state.commit("initConnectCount");
    },
    setTodaysLock: function(state, flg) {
      state.commit("setTodaysLock", flg);
    },
    // getTransfers: function({ commit }) {
    //   return axios.get("/_testData/transfer.json").then((response) => {
    //     commit("setTransfers", response.data);
    //   });
    // },
    getShops: function({ commit }) {
      return axios.get("/_testData/shop.json").then(response => {
        commit("setShops", response.data);
      });
    },
    getOwners: function({ commit }) {
      return axios.get("/_testData/owner.json").then(response => {
        commit("setOwners", response.data);
      });
    }
  },
  getters: {
    token(state) {
      let cookieName = "__jwt_token";
      if (window.location.pathname.indexOf("/iseya") === 0) {
        cookieName = "__jwt_token_iseya";
      }
      if (!state.token) {
        if (!VueCookies.get(cookieName)) {
          return "";
        }
        state.token = VueCookies.get(cookieName);
      }
      return state.token;
    },
    connecting(state) {
      return state.connecting;
    },
    getConnectCount(state) {
      return state.connectCount;
    },
    async checkRock(state) {
      if (!state.todaysLock) {
        const api = new ApiClient();
        const result = await api.get("/iseya/rock/check");
        if (result.check === 1) {
          state.todaysLock = true;
        }
      }
      return state.todaysLock;
    },
    async transfers(state) {
      if (!state.transfers) {
        const api = new ApiClient();
        const result = await api.get("/iseya/transfer/list");
        state.transfers = result.list;
      }
      return state.transfers;
    },
    async prefectures(state) {
      if (!state.prefectures) {
        const api = new ApiClient();
        const result = await api.get("/pref");
        state.prefectures = result.list;
      }
      return state.prefectures;
    }
  }
});
