import ApiClient from "@/module/api";

export default {
  state: {
    data: {
      fetchComplete: true,
      transferDays: 0,
      order: []
    }
  },
  getters: {},
  mutations: {
    async initOrderData(state, { store_id, other_id, extra }) {
      const api = new ApiClient();
      const param = {
        store_id: store_id,
        other_id: other_id ? other_id : 0,
        extra: extra ? 1 : 0
      };
      const result = await api.post("/store/order/list", param);
      state.data.transferDays = result.transferDays;
      state.data.order = result.list;
    },
    setOrderData(state, orderData) {
      state.data.order = orderData;
    },
    mathTotalData(state, { index }) {
      let thisDayQt = 0;
      let thisDayWeight = 0;
      let thisDaySubTotal = 0;
      for (let $i = 0; $i < state.data.order[index].products.length; $i++) {
        //合計の数
        thisDayQt =
          thisDayQt +
          state.data.order[index].products[$i].quantity +
          state.data.order[index].products[$i].add_quantity;
        thisDayWeight +=
          (state.data.order[index].products[$i].quantity +
            state.data.order[index].products[$i].add_quantity) *
          state.data.order[index].products[$i].orderUnitWeight;

        thisDaySubTotal +=
          (state.data.order[index].products[$i].quantity +
            state.data.order[index].products[$i].add_quantity) *
          state.data.order[index].products[$i].price;
      }
      state.data.order[index].thisDayQt = thisDayQt;
      state.data.order[index].thisDayWeight = thisDayWeight;
      state.data.order[index].thisDayBoxQt = Math.ceil(thisDayWeight / 20000);
      state.data.order[index].thisDaySubTotal = thisDaySubTotal;
    },
    // minus(state, { itemID, index }) {
    //   //当該商品の個数
    //   let targetIndex = 0;
    //   for (let $i = 0; $i < state.data.order[index].products.length; $i++) {
    //     if (state.data.order[index].products[$i].item_id === itemID) {
    //       state.data.order[index].products[$i].quantity--;
    //       targetIndex = $i;
    //       break;
    //     }
    //   }

    //   //0個以下にならないようにする、0個になったら薄くする
    //   if (state.data.order[index].products[targetIndex].quantity <= 0) {
    //     state.data.order[index].products[targetIndex].quantity = 0;
    //   }
    // },
    // plus(state, { targetProduct, index }) {
    //   //当該商品の個数
    //   let product = {};
    //   for (let $i = 0; $i < state.data.order[index].products.length; $i++) {
    //     product = state.data.order[index].products[$i];
    //     if (product.item_id === targetProduct.item_id) {
    //       state.data.order[index].products[$i].quantity += 1;
    //       break;
    //     }
    //   }
    // },

    vacuumMinus(state, { id, orderNum }) {
      //当該商品の個数
      state.data.order[orderNum].product[id].vacuumQuantity--;
      //0個以下にならないようにする
      if (state.data.order[orderNum].product[id].vacuumQuantity <= 0) {
        state.data.order[orderNum].product[id].vacuumQuantity = 0;
      }
    },
    vacuumPlus(state, { id, orderNum }) {
      //当該商品の個数

      let quantity = state.data.order[orderNum].product[id].quantity;
      let vacuumQuantity =
        state.data.order[orderNum].product[id].vacuumQuantity;
      if (quantity > vacuumQuantity) {
        state.data.order[orderNum].product[id].vacuumQuantity++;
      }
    }
  },
  actions: {
    //plus(context, number) {
    //  context.commit("plus", number);
    //},
  }
};
